import React from 'react'
import HomePage from '../../components/home-page/HomePage'

const Home = () => {
  return (
    <>
      <main>
        <HomePage/>
      </main>
    </>
  )
}

export default Home