import AllRoutes from "./routes";

function App() {
  return (
    <>
        <AllRoutes/>
    </>  
  );
}

export default App;
